import React, { useEffect, useState } from 'react';

const ClearCacheButton = () => {
  const [isNewVersion, setIsNewVersion] = useState(false);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', event => {
        if (event.data && event.data.type === 'NEW_VERSION') {
          setIsNewVersion(true);
        }
      });
    }
  }, []);

  const clearCacheAndReload = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        registrations.forEach(registration => {
          registration.unregister();
        });
      });
      caches.keys().then(cacheNames => {
        return Promise.all(
          cacheNames.map(cacheName => {
            return caches.delete(cacheName);
          })
        );
      }).then(() => {
        window.location.reload(true);
      });
    }
  };

  return (
    isNewVersion && (
      <button onClick={clearCacheAndReload}>
        En ny version er tilgængelig. Klik for at opdatere.
      </button>
    )
  );
};

export default ClearCacheButton;
