import React, { useEffect, useState } from 'react';

const InstallButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setShowInstallButton(true); // Show the install button
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      setDeferredPrompt(null);
    }
  };

  // For iOS, provide a message on how to add to home screen
  const renderIosInstructions = () => (
    <div className='install-wrapper'>
<p>For at installere denne app, skal du trykke på <strong>Del</strong>-ikonet nedenfor og vælge <strong>"Føj til hjemmeskærm"</strong>.</p>
</div>
  );

  // Render the install button for Android users
  const renderInstallButton = () => (
    <div className='install-wrapper'>
      <button className="btn-install" onClick={handleInstallClick}>
        Add to Home Screen
      </button>
    </div>
  );

  return showInstallButton ? renderInstallButton() : renderIosInstructions();
};

export default InstallButton;
