import React, { useState, useEffect, useRef } from 'react';

const PageIndicator = () => {
  const [currentPage, setCurrentPage] = useState(1); // Initial current page number state
  const pageIndicatorRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const pageSeparators = document.querySelectorAll('.page-separator');
      let newPageNumber = currentPage; // Start with current page number

      // Check which page-separator is in view
      pageSeparators.forEach((separator) => {
        const rect = separator.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= (window.innerHeight / 2)) {
          const pageNumber = parseInt(separator.getAttribute('data-pagenumber'));
          if (pageNumber !== currentPage) {
            newPageNumber = pageNumber;
          }
        }
      });

      // Update current page state only if a new page number is found
      if (newPageNumber !== currentPage) {
        setCurrentPage(newPageNumber);
      }
    };

    // Attach scroll event listener to window
    window.addEventListener('scroll', handleScroll);

    // Initial call to set the current page number based on initial scroll position
    handleScroll();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage]); // useEffect dependency

  return (
    <div className='page-indicator' ref={pageIndicatorRef}>
      {currentPage}
    </div>
  );
};

export default PageIndicator;
