import React from 'react';
import { ReactComponent as CloseIcon } from '../assets/icons/close-icon.svg';

const TableOfContents = ({ tableOfContents, tocVisible, toggleToc }) => {
  return (
    <div className={`table-of-contents${tocVisible ? ' show' : ' hidden'}`}>
      <button onClick={toggleToc}><CloseIcon /></button> 
      <h2>Indhold</h2>
      <div>
        <ul>
          {tableOfContents.map((item, index) => (
            <li key={index} className={`toc-level-${item.level}`}>
              <a onClick={toggleToc} href={`#${item.id}`}>{item.text}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TableOfContents;
