import { useState, useEffect } from 'react';

const useFetchHtmls = (getHtmlPaths, fetchHtmlContent) => {
  const [htmlContents, setHtmlContents] = useState({});

  useEffect(() => {
    const fetchAllHtmls = async () => {
      try {
        const paths = await getHtmlPaths();
        const promises = paths.map((path) => fetchHtmlContent(path));
        const contents = await Promise.all(promises);

        const htmlMap = contents.reduce((acc, { path, content }) => {
          acc[path] = content;
          return acc;
        }, {});

        setHtmlContents(htmlMap);
      } catch (error) {
        console.error("Error fetching HTML files:", error);
      }
    };

    fetchAllHtmls();

    // Toggle References
    function handleClick(event) {
      if (event.target.classList.contains('references')) {
        event.target.classList.toggle('hidden');
      }
    }
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
    // End of Toggle References

  }, [getHtmlPaths, fetchHtmlContent]);

  return htmlContents;
};

export default useFetchHtmls;
