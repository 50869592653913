import React from 'react';
import { ReactComponent as LoginGraphic } from '../assets/icons/graphic.svg';

const NavigationSplash = ({ splashVisible, setSplashVisible, toggleSearch, toggleToc, toggleHome, searchVisible, tocVisible }) => {

  return (
    splashVisible && (
<nav className='navigation-splash'>
<div id="home-nav" onClick={toggleHome} className={(searchVisible || tocVisible) ? 'nav-item' : 'nav-item active'}>
 Indledning
</div>
<div id="search-nav" onClick={toggleSearch} className={searchVisible ? 'nav-item active' : 'nav-item'}>
Søg
</div>
<div id="toc-nav" onClick={toggleToc} className={tocVisible ? 'nav-item active' : 'nav-item'}>
 Indholdsfortegnelse
</div>
<LoginGraphic />
</nav>
)
);
};

export default NavigationSplash;