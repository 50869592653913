import React from 'react';
import { ReactComponent as SearchIcon } from '../assets/icons/search-icon-small.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/close-icon.svg';


  // Function to lose focus when pressing 'Enter'
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.target.blur(); // Blur the input field
    }
  };


const SearchComponent = ({ searchQuery, handleSearchInputChange, toggleSearch, searchResults, searchVisible, handleSearch, clearInput, searchInputRef }) => {
  return (
    <div className={`search-container${searchVisible ? ' show' : ' hidden'}`}>
        <div className='search-container-inner'>
         <div className='search-header'>
              <div className='search-input-wrapper'>
                <input
                  id="search-input"
                  type="text"
                  placeholder="Søg..."
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  ref={searchInputRef}
                  onKeyDown={handleKeyDown}
                />
                <div><SearchIcon /></div>
              <button onClick={clearInput}><CloseIcon /></button>
            </div>
          </div>

          <div className="search-results">
            
          {searchResults.length > 0 ? (
            <ul>
            {searchResults.map((result, index) => (
              result.closestHeadingText !== "Referencer" && (
                <li key={index}>
                  {result.element === 'P' ? (
                    <>
                      <h3><a onClick={toggleSearch} href={`#${result.id}`}>{result.closestHeadingText}</a></h3>
                      <a onClick={toggleSearch} href={`#${result.id}`} dangerouslySetInnerHTML={{ __html: result.content }}></a>
                    </>
                  ) : (
                    <h3><a onClick={toggleSearch} href={`#${result.id}`} dangerouslySetInnerHTML={{ __html: result.content }}></a></h3>
                  )}
                </li>
              )
            ))}
          </ul>

            ) : (
              <p>{searchQuery.length > 0 ? 'Ingen resultater' : ''}</p>
            )}
          </div>
      </div>
    </div>
  );
};

export default SearchComponent;
