import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ReactComponent as LoginGraphic } from './assets/icons/graphic.svg';
import { ReactComponent as LoginSeparator } from './assets/icons/login-separator.svg';
import './assets/css/style.css';
import InstallButton from './components/InstallButton';
import SearchField from './components/Search';
import TableOfContents from './components/TableOfContents';
import NavigationSplash from './components/NavigationSplash';
import Navigation from './components/Navigation';
import PageIndicator from './components/PageIndicator';
import useFetchHtmls from './hooks/useFetchHtmls';
import ClearCacheButton from './components/ClearCacheButton';



function App() {
  const [processedHtmlContents, setProcessedHtmlContents] = useState({});
  const [tableOfContents, setTableOfContents] = useState([]);
  const [tocVisible, setTocVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const searchInputRef = useRef(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [password] = useState('1234');
  const [splashVisible, setSplashVisible] = useState(true);

  // Check if user is already authenticated from localStorage on component mount
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('authenticated');
    if (isAuthenticated) {
      setAuthenticated(true);
    }
  }, []);

  //Get content - add more here and in service-worker.js for it to be cached
  const getHtmlPaths = useCallback(async () => {
    const basePath = process.env.PUBLIC_URL || '';
    return [
      `${basePath}/content/content-1.html`,
      `${basePath}/content/content-2.html`,
      `${basePath}/content/content-3.html`,
      `${basePath}/content/content-4.html`,
      `${basePath}/content/content-5.html`,
      `${basePath}/content/content-6.html`,
      `${basePath}/content/content-7.html`,
      `${basePath}/content/content-8.html`,
      `${basePath}/content/content-9.html`,
      `${basePath}/content/content-10.html`,
      `${basePath}/content/content-11.html`,
      `${basePath}/content/content-12.html`,
      `${basePath}/content/content-13.html`,
      `${basePath}/content/content-14.html`,
      `${basePath}/content/content-15.html`,
      `${basePath}/content/content-16.html`
    ];
  }, []);

  const fetchHtmlContent = useCallback(async (path) => {
    const response = await fetch(path);
    return { path, content: await response.text() };
  }, []);

  // Custom hook for fetching HTML content
  const htmlContents = useFetchHtmls(getHtmlPaths, fetchHtmlContent);

    // Initialize a ref to hold the global paragraph counter
    const paragraphCounter = useRef(0);

    // Effect to process HTML content
    useEffect(() => {
      if (Object.keys(htmlContents).length === 0) return;
  
      const toc = [];
      const updatedHtmlContents = {};
  
      Object.entries(htmlContents).forEach(([path, content]) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const headings = Array.from(doc.querySelectorAll('h1, h2, h3'));
        const paragraphs = Array.from(doc.querySelectorAll('p'));
  
        paragraphs.forEach((paragraph) => {
          let paragraphId = paragraph.getAttribute('id');
          if (!paragraphId) {
            // Use the global paragraph counter for unique IDs
            paragraphId = 'paragraph-' + paragraphCounter.current++;
            paragraph.setAttribute('id', paragraphId);
          }
        });
  
        headings.forEach((heading, index) => {
          const headingText = heading.textContent;
          let headingId = heading.getAttribute('id');
          if (!headingId) {
            headingId = headingText.replace(/\s+/g, '-').toLowerCase() + '-' + index; // Ensure unique IDs
            heading.setAttribute('id', headingId);
          }
          const headingLevel = heading.tagName.toLowerCase();
          toc.push({ text: headingText, id: headingId, level: headingLevel });
        });
  
        // Update HTML content with IDs
        updatedHtmlContents[path] = doc.body.innerHTML;
      });
  
      setTableOfContents(toc);
      setProcessedHtmlContents(updatedHtmlContents);
    }, [htmlContents]);


// Function to handle search
const handleSearch = (query) => {
  const searchQuery = query.trim(); // Use the passed query

  if (!searchQuery) {
    setSearchResults([]);
    return;
  }

  const searchWords = searchQuery.toLowerCase().split(' ');
  const results = [];

  Object.entries(processedHtmlContents).forEach(([path, content]) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    const elementsToSearch = ['p']; // Adjust as needed

    elementsToSearch.forEach((element) => {
      const elements = doc.querySelectorAll(element);

      elements.forEach((el) => {
        const textContent = el.textContent.toLowerCase();

        // Check if any of the search words are present in the element's text content
        const found = searchWords.some((word) => textContent.includes(word));
        
        if (found) {
          let modifiedContent = el.innerHTML;

          searchWords.forEach((word) => {
            const regex = new RegExp(`(${word})`, 'gi');
            modifiedContent = modifiedContent.replace(regex, '<mark>$1</mark>');
          });
          let closestHeadingText = '';

          // Check if the element is a paragraph (p)
          if (element === 'p') {
            
            // Case 1: Check if p is inside an li element
            let listItem = el.closest('ul'); // Find the closest ancestor li element
            let refItem = el.closest('div.references');
            if (listItem) {
              let closestHeading = listItem.previousElementSibling;
              while (closestHeading) {
                // Check if the closest heading is h1, h2, h3, or h4
                if (closestHeading.tagName === 'H1' || closestHeading.tagName === 'H2' || closestHeading.tagName === 'H3' || closestHeading.tagName === 'H4') {
                  closestHeadingText = closestHeading.textContent;
                  break;
                }
                closestHeading = closestHeading.previousElementSibling;
              }
            }

            if (refItem) {
              closestHeadingText = "Referencer";
            }

            // Case 2: Check if p is not inside an li element
            if (!listItem) {
              let closestHeading = el.previousElementSibling;
              while (closestHeading) {
                // Check if the closest heading is h1, h2, h3, or h4
                if (closestHeading.tagName === 'H1' || closestHeading.tagName === 'H2' || closestHeading.tagName === 'H3' || closestHeading.tagName === 'H4') {
                  closestHeadingText = closestHeading.textContent;
                  break;
                }
                closestHeading = closestHeading.previousElementSibling;
              }
            }
          }


          results.push({
            path,
            element: el.tagName,
            content: modifiedContent,
            id: el.id,
            closestHeadingText: closestHeadingText
          });
        }
      });
    });
  });

  setSearchResults(results);
};

  
// Function to handle search query changes
const handleSearchInputChange = (event) => {
  const { value } = event.target;
  setSearchQuery(value);
  handleSearch(value); // Pass the current value to handleSearch
  
};


const clearInput = () => {
  const value = "";
  setSearchQuery(value);
  handleSearch(value); // Pass the current value to handleSearch
  searchInputRef.current.focus();
};

//End of search query

document.addEventListener('scroll', function() {
  const headers = document.querySelectorAll('h1');
  headers.forEach(header => {
    const rect = header.getBoundingClientRect();
    if (rect.top <= 50) {
      // Header is in sticky mode
      header.style.minHeight = '0';
    } else {
      // Header is not in sticky mode
      header.style.minHeight = '100px';
    }
  });
});




const scrollToTop = (smooth) => {
  window.scrollTo({
    top: 0,
    behavior: smooth
  });
};


const toggleHome = () => {
  setTocVisible(false);
  setSearchVisible(false);
  setSplashVisible(false);
  scrollToTop();
};

  const toggleToc = () => {
    setTocVisible(!tocVisible);
    setSearchVisible(false);
    setSplashVisible(false);
  };

  const toggleSearch = () => {   
    setSearchVisible(!searchVisible);
    setTocVisible(false);
    setSplashVisible(false);
  };

  // Handle login
  const handleLogin = (enteredPassword) => {
    if (enteredPassword === password) {
      setAuthenticated(true);
      localStorage.setItem('authenticated', 'true'); // Store authenticated status in localStorage
    } else {
      alert('Incorrect password');
    }
  };

  

   // Focus on input field whenever searchVisible changes
   useEffect(() => {
    if (searchVisible && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchVisible]);



  return (
    <div className="App">

<ClearCacheButton />


      {/* Conditional rendering based on authentication */}
      {!authenticated ? (
        <div className='login-wrapper'>
          <div className='login-inner'>
          <h1>Retningslinier</h1>
          <LoginSeparator />
          <p>For diagnostik og behandling <br/> af primær immundefekt</p>
          <form onSubmit={(e) => { e.preventDefault(); handleLogin(e.target.password.value); }}>
            <input type="password" name="password" placeholder="Kodeord" />
            <button type="submit">Log ind</button>
          </form>
          <div className='login-app-btn'>
          <InstallButton/>
          </div>
          </div>
          <LoginGraphic />
        </div>
      ) : (
        <div>

          <NavigationSplash 
            toggleToc={toggleToc} 
            toggleSearch={toggleSearch} 
            toggleHome={toggleHome} 
            searchVisible={searchVisible} 
            tocVisible={tocVisible} 
            splashVisible={splashVisible}
          />

          <SearchField 
            searchQuery={searchQuery} 
            handleSearchInputChange={handleSearchInputChange} 
            searchResults={searchResults}
            searchVisible={searchVisible}
            toggleSearch={toggleSearch}
            clearInput={clearInput}
            searchInputRef={searchInputRef}
          />

          <TableOfContents 
            tableOfContents={tableOfContents} 
            tocVisible={tocVisible} 
            toggleToc={toggleToc} 
          />
          <PageIndicator />

          <div>
            {Object.entries(processedHtmlContents).map(([path, content]) => (
              <section className="content" key={path} dangerouslySetInnerHTML={{ __html: content }} />
            ))}
          </div>
          
          <Navigation 
            toggleToc={toggleToc} 
            toggleSearch={toggleSearch} 
            toggleHome={toggleHome} 
            searchVisible={searchVisible} 
            tocVisible={tocVisible} 
          />
        </div>
      )}
    </div>
  );
}

export default App;